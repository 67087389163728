<template>
  <div class="auth login d-flex justify-center align-center flex-col">
    <div class="logo d-flex justify-center align-center">
      <img src='@/assets/images/logo.png' alt='' style="width: 300px;" >
    </div>
    <div class="form-login box-shadow">
      <div class="title text-center">Sign in</div>
      <a-form-model :model="form" :rules="rules" ref="form" class="form-wrap">
        <a-form-model-item prop="username" ref="username" class="form-item">
          <a-input
              placeholder="Username..."
              v-model.trim="form.username"
          >
            <a-tooltip slot="suffix">
              <a-icon type="mail"/>
            </a-tooltip
            >
          </a-input>
        </a-form-model-item>
        <a-form-model-item
            prop="password"
            ref="password"
            class="form-item form-item-pw"
        >
          <a-input-password
              placeholder="Password..."
              v-model.trim="form.password"
          ></a-input-password>
        </a-form-model-item>
        <a-form-model-item class="form-item form-item-pw">
          <div class="d-flex justify-between align-center">
            <router-link to="/login" style="font-size: 14px"
            >Forgot password?
            </router-link
            >
            <a-button type="primary" htmlType="submit" @click="submitForm(form)"
                      :loading="loadingForm"
            >Login
            </a-button
            >
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/app/auth.service'

export default {
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: "",
        remember: false,
      },
      loadingForm: false,
      rules: {
        username: [
          {
            required: true,
            message: "Username is not empty",
            trigger: "change",
          },
        ],
        password: [
          {required: true, message: "Password is not empty", trigger: "change"},
        ],
      },
    }
  },

  methods: {
    submitForm(form) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.onSubmit(form);
        }
      });
    },

    onSubmit(form) {
      let body = {
        username: form.username,
        password: form.password,
      };
      this.loadingForm = true;
      AuthService.login(body)
          .then((res) => {
            if (res.code === 1) {
              if (res.data) {
                localStorage.setItem("Access-Token", res.data.token);
                localStorage.setItem("Token-Expire", res.data.expiredTime);
                localStorage.setItem("User-Info", JSON.stringify(res.data.userInfo))
                this.$notification.success({
                  message: 'Sign in successfully'
                })
                setTimeout(() => {
                  this.$router.push("/dashboard");
                }, 1500);
              }
            } else {
              // notification('warning', 'Có lỗi xảy ra', res.message || 'Vui lòng thử lại')
              this.$notification.warning({
                message: 'Something went wrong',
                description: res && res.message ? res.message : 'Please try again'
              })
            }
            this.loadingForm = false;
          })
          .catch((err) => {
            this.$notification.warning({
              message: err && err.message ? err.message : 'Vui lòng thử lại'
            })
            this.loadingForm = false;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  background-color: #0a709c;
  height: 100vh;

  .logo {
    margin-bottom: 24px;
    img {
      width: 90px;
      height: auto;
    }
  }

  .form-login {
    width: 360px;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;

    .title {
      color: #199ed8;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .form-wrap {
      .form-item {
        margin-bottom: 16px;
      }
    }
  }
}
</style>

